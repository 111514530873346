.tn-account-login-page {
    max-width: 900px;
    margin: $spacer-m auto;
    .tn-account-login-forgot-login-link {
        background-color: transparent;
        border: none;
        padding: 0;
        border-radius: 0;
        font-weight: bold;
        color: inherit;
        text-underline-offset: 3px;
        text-decoration-thickness: 2px;
        transition: all 250ms ease;
        &:hover {
            color: inherit;
        }
    }
    .tn-register-button {
        text-decoration: none;
    }
}

