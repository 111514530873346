html {
    @include font-size-base;
    overflow-y: scroll; /* [2] */
    min-height: 100%; /* [3] */
    @include ff-body;
    background-color: $sbc-yellow;
    color: $black;
    accent-color: $accent-colour;
    padding: 0 2%;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
}

.c-page-wrap {
    margin: 0 auto;
    max-width: $max-site-width;
    background-color: $warm-white;
    border-radius: $global-radius;
    min-height: 90vh;

    * {
        overflow-wrap: break-word;
    }
    
    h1, h2, h3 {
        @include ff-heading;
    }
    
    // Paragraphs
    p {
        line-height: 1.5;
    }
    
    // Anchor tags
    a {
        font-weight: 700;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 2px;
        transition: all 250ms ease;
    }
    
    a, :-moz-any-link:focus { outline: none; }

    .tn-heading-error {
        border: 2px solid $warning;
    }

    .tn-btn-link {
        text-decoration: none;
    }
    
    .tn-required-field {
        margin-bottom: 3px;
    }
    .has-error {
        .glyphicon {
            display: none;
        }
    }

}

.modal {
    a {
        font-weight: 700;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-thickness: 2px;
        transition: all 250ms ease;
    }
}



