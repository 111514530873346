///* ========================================================================
//   #FONT FILES
//   ======================================================================== */

// Add your font-face links to fonts here


$font-path: "https://southbank-centre-tnew.s3.amazonaws.com/qa/fonts";

@font-face {
     font-family: "SC Noe Bold";
     src: url("#{$font-path}/sc-noe-bold.woff") format("woff");
     font-weight:  400;
     font-stretch: normal;
}
 
@font-face {
     font-family: "Akkurat";
     src: url("#{$font-path}/akkurat-regular.woff") format("woff");
     font-weight:  400;
     font-stretch: normal;
}

@font-face {
     font-family: "Akkurat";
     src: url("#{$font-path}/akkurat-bold.woff") format("woff");
     font-weight:  700;
     font-stretch: normal;
}


/* ==========================================================================
   #Fonts
   ========================================================================== */

// Font Face
@mixin ff-body {
     font-family: "Akkurat", sans-serif;
 }
 
 @mixin ff-heading {
     font-family: "SC Noe Bold", serif;
     font-weight: 400;
 }
 
 @mixin font-thin {
     font-weight: 100;
 }
 @mixin font-light {
     font-weight: 300;
 }
 @mixin font-reg {
     font-weight: 400;
 }
 @mixin font-med {
     font-weight: 500;
 }
 @mixin font-semi-bold {
     font-weight: 600;
 }
 @mixin font-bold {
     font-weight: 700;
 }
 @mixin font-heavy {
     font-weight: 800;
 }
 @mixin font-super-heavy {
     font-weight: 800;
 }
 @mixin font-italic {
     font-style: italic;
 }
 
 
 
 /* Font awesome fonts
    ========================================================================== */
 
 @mixin fa-reg {
     font-family: "Font Awesome 6 Pro";
     @include font-reg;
 }
 
 @mixin fa-thin {
     font-family: "Font Awesome 6 Pro";
     @include font-thin;
 }
 
 @mixin fa-light {
     font-family: "Font Awesome 6 Pro";
     @include font-light;
 }
 
 @mixin fa-solid {
     font-family: "Font Awesome 6 Pro";
     @include font-super-heavy;
 }
 
 @mixin fa-brands {
     font-family: "Font Awesome 6 Brands";
 }
 
 @mixin fa-duotone {
     font-family: "Font Awesome 6 Duotone";
 }
 
 
 
 