
.tn-pre-cart-page {
    max-width: 800px;
    margin: $spacer-m auto;
    label {
        font-weight: bold;
    }
    .list-unstyled {
        margin-left: 25px;
    }
    .progress-tracker {
        display: none;
    }
    .sc-tnew-direct-debit-guarantee {
        font-size: 80%;
        .container {
            width: 100%;
        }
    }
    
    .TNEW_steps_block,
    .tn-plug-in-direct-debit-page-actions {
        .btn {
            padding: 9px 20px;
            font-size: 18px;
        }
    }
}

