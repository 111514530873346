/* ==========================================================================
   #MAX WIDTHS
   ========================================================================== */

// Loops through various widths to create classes for max-widths

$widths: 400, 500, 600, 700, 800, 900, 1000, 1200, 1400, 1600;
@each $width in $widths {
    .o-max--#{$width} {
        max-width: $width + px;
        margin-left: auto;
        margin-right: auto;
    }
}
// If you need your max-width element to be centred, add a class of block--centred
.o-max--no-center {
    margin-left: 0;
    margin-right: 0;
}


.o-site-max {
    max-width: $max-site-width;
}
