.tn-subnav-component {
	padding: $spacer-s 0 0;
	justify-content: center;
	@include mq($from: desktop){
		justify-content: flex-end;
	}
	max-width: 1200px;
	margin: 0 auto;
	> div:last-of-type {
		display: none;
	}
	.tn-cart-link {
		margin: 0;
	}
	.tn-cart-link.tn-active {
		flex: 0;
		.tn-timer {
			display: inline-block;
			border-radius: 100px;
			padding: 6px 12px;
			border: 2px solid $black--20;
			color: $black--80;
			font-weight: bold;
			font-size: 0.8rem;
			margin-right: $spacer-s;
			.tn-timer-description {
				display: inline-block;
			}
		}
		.tn-link {
			display: none;
		}
	}
	.tn-login-link {
		display: none;
	}

	.tn-promo-box {
        margin-left: 0;
        form {
            display: flex;
			align-items: center;
			gap: 3px;
        }
        input.tn-subnav-promo-code {
			max-width: 150px;
            font-size: 0.8rem;
            height: 32px;
			border-radius: $global-radius 0 0 $global-radius;
			border: 1px solid $black--40;
			padding: 3px 8px;
			font-weight: bold;
        }
        button.tn-subnav-promo-button {
            height: 32px;
            text-decoration: none;
			@include c-btn($bg-color: $off-black, $txt-color: $white, $border-color: $black);
			font-size: 0;
			padding: 0;
			line-height: 1;
			margin: 0;
			border-radius: 0 $global-radius $global-radius 0;
			&::after {
				padding: 0 $spacer-s;
				font-size: 0.8rem;
				content: "\f061";
				@include fa-reg;
				display: inline-block;
				pointer-events: none;
			}
        }
    }
	.tn-promo-applied {
		border-radius: 100px;
		border: 2px solid $black--20;
		color: $black--80;
		font-weight: bold;
		margin: 0;
		.tn-text {
			font-size: 0.8rem !important;
			padding: 4px 15px;
			display: inline-block !important;
		}
		&::before {
			display: none;
		}
		.tn-link {
			background-color: transparent;
			border: 2px solid;
			margin: 3px;
			border-radius: 100px;
			padding: 3px 8px;
		}
	}

}
