/* ==========================================================================
   #BUTTONS
   ========================================================================== */

$button-padding-vertical: 10px;
$button-padding-horizontal:  18px;

$button-small-padding-vertical: 6px;
$button-small-padding-horizontal:  12px;

$button-large-padding-vertical: 15px;
$button-large-padding-horizontal: 28px;

$button-transition: all 100ms;
$button-radius: $global-radius;


/* Global button styles (common to all variants)
   ========================================================================== */

@mixin c-btn($bg-color: $sbc-blue, $txt-color: $white, $border-color: darken($sbc-blue, 25%)) {
    display: inline-block;
    vertical-align: middle;
    font: inherit;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin: 0;
    margin-bottom: .5rem;
    cursor: pointer;
    background-color: $bg-color;
    color: $txt-color;
    padding: $button-padding-vertical $button-padding-horizontal;
    transition: $button-transition;
    border-radius: $button-radius;
    text-decoration: none !important;
    border: 1px solid $border-color;
    box-shadow: inset 0px -2px 0px 0px $border-color;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, .4);
    &:active {
        transform: translateY(1px);
        box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, .85);
    }
    i {
        margin-right: $spacer-xs;
    }
    &:focus-visible {
        outline: $warning solid 3px;
        outline-offset: 2px;
    }
}

@mixin c-btn--hover($bg-color: $sbc-blue, $txt-color: $white, $border-color: $black) {
    background-color: $bg-color;
    color: $txt-color;
    border: 1px solid $border-color;
    box-shadow: inset 0px -2px 0px 0px $border-color;
}


/* Button classes & variants
   ========================================================================== */


.c-btn {
    @include c-btn;

    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover($bg-color: darken($sbc-blue, 20%), $txt-color: $white, $border-color: $black);
    }

}

.c-btn--blue {
    @include c-btn($bg-color: $sbc-blue, $txt-color: $white, $border-color: darken($sbc-blue, 25%));
    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover($bg-color: darken($sbc-blue, 20%), $txt-color: $white, $border-color: $black);
    }
}
.c-btn--black {
    @include c-btn($bg-color: $black, $txt-color: $white, $border-color: $black);
    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover($bg-color: $white, $txt-color: $black);
        text-shadow: none !important;
    }
    .c-container.u-black-bg .c-col:not(.c-col--has-bg-color) &,
    .c-container.u-off-black-bg .c-col:not(.c-col--has-bg-color) &,
    .c-col.u-black-bg &,
    .c-col.u-off-black-bg & {
        @include c-btn($bg-color: $off-black, $txt-color: $white, $border-color: $white);
        &:hover,
        &:active,
        &:focus {
            @include c-btn--hover($bg-color: $white, $txt-color: $black);
        }
    }
}
.c-btn--ghost {
    @include c-btn($bg-color: transparent, $txt-color: $black, $border-color: $black);
    text-shadow: none;
    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover($bg-color: $black, $txt-color: $white, $border-color: $black);
    }
    .c-container.u-black-bg .c-col:not(.c-col--has-bg-color) &,
    .c-container.u-off-black-bg .c-col:not(.c-col--has-bg-color) &,
    .c-col.u-black-bg &,
    .c-col.u-off-black-bg & {
        @include c-btn($bg-color: transparent, $txt-color: $white, $border-color: $white);
        &:hover,
        &:active,
        &:focus {
            @include c-btn--hover($bg-color: $white, $txt-color: $black, $border-color: $black);
        }
    }
}



/* Has Spinner
    ========================================================================== */


.c-btn--has-spinner {
    position: relative;
    &::after {
        content: "";
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: inherit;
        z-index: 1;
        border-radius: $button-radius;
    }
    &::before {
        @include fa-reg;
        @extend .fas !optional;
        @extend .fa-spin !optional;
        @extend .fa-spinner-third !optional;
        opacity: 0;
        position: absolute;
        left: 50%;
        margin-left: -10px;
        line-height: inherit;
        z-index: 2;
        color: currentColor;
        display: inline-block;
    }
    &.active {
        opacity: 1;
        cursor: wait;

        &:before {
            opacity: 1;
            color: $white;
        }

        &:after {
            opacity: 1;
        }
    }
    &.c-btn--ghost.active  {
        background-color: $black;
        &:before {
            opacity: 1;
            color: $white;
        }

        &:after {
            opacity: 1;
            background-color: $black;
        }
    }
}



/* Mixin to add CTA arrow
   ========================================================================== */

@mixin c-btn--cta {
    position: relative;

    &::after {
        content: "\f061";
        @include fa-reg;
        padding-left: $spacer-s;
        display: inline-block;
        transition: $global-transition;
    }

}

.c-btn--cta {
    @include c-btn--cta;
    &:hover::after,
    &:active::after,
    &:focus::after {
        transform: translateX(2px);
    }
}

/* Disabled button style
   ========================================================================== */

.c-btn--disabled {
    @include c-btn($bg-color: $black--70, $txt-color: $off-white, $border-color: $black--80);
    text-shadow: none;
    pointer-events: none;
}


/* Size variants
   ========================================================================== */


.c-btn--large {
    padding: $button-large-padding-vertical $button-large-padding-horizontal;
    font-size: 1.1rem;
}

.c-btn--small {
    padding: $button-small-padding-vertical $button-small-padding-horizontal;
    font-size: .9rem;
}


/*  Call to action
    ========================================================================== */

@mixin c-cta {
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    display: inline-block;
    cursor: pointer;
    margin-right: 1.5rem;
    text-wrap: pretty;
    &::after {
        @include fa-reg;
        content: "\f061";
        font-size: 80%;
        @include font-reg;
        padding-left: $spacer-s;
        display: inline-block;
        color: currentColor;
    }
}

.c-cta {
    @include c-cta;
}

@mixin c-cta--back {
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    display: inline-block;
    cursor: pointer;
    margin-right: 1.5rem;
    &::before {
        @include fa-reg;
        content: "\f060";
        font-size: 80%;
        @include font-reg;
        padding-right: $spacer-s;
        display: inline-block;
        color: currentColor;
    }
    &::after {
        content: "";
    }
}

.c-cta--back {
    @include c-cta--back;
}

.c-read-more {
    font-size: .9rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    font-weight: 700;
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
    margin-bottom: $spacer-m;
    cursor: pointer;
    &::after {
        @include fa-reg;
        content: "\2b";
        font-size: 90%;
        margin-left: $spacer-s;
        display: inline-block;
        color: currentColor;
    }
    &[aria-expanded="true"]::after {
        transform: rotate(45deg);
    }
}


/* Accessibility Skip link button
   ========================================================================== */

.c-btn--skip-link {
    @include c-btn;
    margin: 0 auto;
    position: absolute;
    z-index: 2000;
    left: $spacer-s;
    right: $spacer-s;
    top: 1em;
    max-width: 20em;
    opacity: 1;
    &:hover,
    &:active,
    &:focus {
        @include c-btn--hover;
    }
}

.c-btn--skip-link:not(:focus):not(:hover) {
    -webkit-transform: translateY(-4em);
    -ms-transform: translateY(-4em);
    transform: translateY(-4em);
    opacity: 0;
}
