// Calendar controls

.tn-btn-datepicker {
    .glyphicon-calendar {
        color: $black;
    }
}

.tn-events-listing-page {
    .tn-event-listing-mode-tab-nav__list-item {
        color: $link-color;
        font-weight: 700;
    }
    .tn-event-listing-view__results-heading {
        color: $black;
    }
    .tn-events-calendar__table th {
        background-color: #eee;
        color: $black;
        font-weight: bold;
    }
}


// Calendar main


.tn-prod-listing {
    a {
        color: $text-color;
    }
}
.tn-perf-listing-action {
    @extend .btn;
    @extend .btn-primary;
    text-align: center;
}

.tn-events-filter-component {
    font-size: 80%;
    .row:first-of-type {
        border-bottom: 1px solid $black--20;
    }
    h3 {
        font-size: 100%;
    }
}

// Event Listing
.tn-events-calendar__table {
    .tn-events-calendar__event {
        @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
        text-decoration: none;
        font-size: 85%;
        border-left: 2px solid $sbc-blue;
        color: $black;
        background-color: $white;
        box-shadow: 0 0 3px rgba(0,0,0,0.1);
        &:hover {
            color: $black;
        }
    }
}
