.tn-account-interests-page,
.tn-account-update-page,
.tn-upcoming-events-page,
.tn-account-memberships-page,
.tn-digital-content-page {
    max-width: 1200px;
    margin: $spacer-m auto;
}

.tn-upcoming-event__list {
    .tn-upcoming-event__list-item {
        border: 1px solid $black--20;
        padding: $spacer-s;
    }
    .tn-upcoming-event__title {
        margin-bottom: 0;
    }
    .tn-upcoming-event__heading {
        margin-top: 0;
    }
}

.tn-account-create-brief-page {
    max-width: 600px;
    margin: $spacer-m auto;
    .tn-heading {
        margin-bottom: $spacer-s;
    }
    .col-sm-6 {
        width: 100%;
    }
}

.tn-interests {
    @include mq($from: desktop){
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: $spacer-m;
    }
}
