.tn-payment-page {
    max-width: 900px;
    margin: $spacer-m auto;
    .tn-payment-submit-component {
        margin-bottom: 200px;
        .btn-primary {
            font-size: 1.1rem;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;
            text-decoration: none;
        }
    }
    .tn-patron-billing-information {
        line-height: 1.7;
    }
    .tn-cart-component {
        .tn-performance-title {
            font-weight: bold;
        }
        .tn-cart-totals * {
            @include ff-body;
            font-weight: bold;
            font-size: 0.9rem;
        }
        .tn-cart-totals__line-item {
            color: $black--70;
        }
        .tn-cart-totals__value--total {
            font-size: 1.2rem;
        }
    }
    .tn-gift-certificates-redemption-component {
        margin: $spacer-s 0 0;
        padding: $spacer-s;
        border: 1px solid $black--20;
        border-radius: $global-radius;
        max-width: 450px;
        * {
            font-size: 0.8rem;
        }
        #tn-payment-apply-gift-certificate-form {
            display: flex;
            align-items: center;
        }
        .form-group {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 0;
        }
        .tn-gift-certificate-id, button {
            border-radius: $global-radius;
            min-height: 40px;
            margin-bottom: 0;
        }
        h2 {
            margin: 0 0 $spacer-s;
            padding-top: 0 !important;
            @include ff-body;
            font-weight: bold;
        }
        .tn-gift-certificate-id-field-label {
            display: none;
        }
    }
    .tn-payment-component {
        h2 {
            margin: $spacer-xs 0;
            padding-top: $spacer-xs !important;
            @include ff-body;
            font-weight: bold;
            font-size: 0.9rem;
        }
    }
    .tn-payment-submit-component {
        margin-top: $spacer-s;
    }
}

