.tn-cart-details-page {
    max-width: 1000px;
    margin: $spacer-m auto;
    .tn-cart-line-item-name {
        font-weight: bold;
    }
    .tn-cart-item-details__list {
        color: $black;
    }
    .tn-heading-info {
        border: 2px solid $sbc-blue;
        font-size: 1rem;
        font-weight: bold;
        margin: $spacer-s 0;
    }
    .tn-cart-totals * {
        @include ff-body;
        font-weight: bold;
        font-size: 0.9rem;
    }
    .tn-cart-totals__line-item {
        color: $black--70;
    }
    .tn-cart-totals__value--total {
        font-size: 1.2rem;
    }
    .tn-cart-buttons__primary-action {
        font-size: 1.1rem;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 100%;
        text-decoration: none;
    }
    .tn-cart-buttons__secondary-action {
        text-decoration: underline;
    }
    .tn-empty-cart-message {
        font-style: normal;
        color: $black;
    }
    .tn-cart-buttons {
        text-align: left;
    }
}
