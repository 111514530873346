.tn-events-detail-page {
    max-width: 1200px;
    margin: 0 auto;
    .tn-prod-season-header {
        margin-bottom: $spacer-s;
        padding-bottom: $spacer-s;
        border-bottom: 1px solid $black--20;
    }
    .tn-prod-season-header__date-container {
        display: none;
    }
    .tn-prod-season-header__title {
        @include ff-heading;
        font-size: 2rem;
    }
    .tn-event-detail__display-time {
        margin-bottom: $spacer-s;
    }
    .tn-ticketing-mode-change__content {
		font-style: normal;
	}
	.tn-event-detail__display-time, .tn-event-detail__location {
		font-weight: bold;
	}
    .tn-ticket-selector__heading-available-zones {
        display: none;
    }
    .tn-event-detail__performance-details-container {
        margin-bottom: $spacer-m;
    }
    .tn-radio-button-list__input:checked+.tn-radio-button-list__button {
        background-color: $sbc-blue;
        border-color: $sbc-blue;
    }
    .selected-time-label, .select-tickets-label {
        font-weight: bold;
        font-size: 1.1rem;
        border-bottom: 1px solid $black--20;
    }
    .tn-ticket-selector__pricetype-name {
        font-weight: bold;
        font-size: 0.8rem;
    }
    .tn-ticket-selector__pricetype-select {
        font-weight: bold;
    }
    .tn-event-detail__unavailable-text {
        background-color: $white;
        color: $black;
        border: 1px solid $black--10;
        border-top: 3px solid $black--70;
        padding: $spacer-s;
        margin-bottom: $spacer-s;
        box-shadow: $global-box-shadow-small;
        @include mq($from: desktop){
            padding: $spacer-m;
            margin-bottom: $spacer-m;
        }
    }
    #tn-add-to-cart-button {
        @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $border-radius-base);
        max-width: none;
    }
    .tn-event-details {
        @include mq($from: desktop){
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: $spacer-m;
            .tn-event-detail__additional-events-container {
                width: min-content;
                margin: 0;
                padding: 0;
            }
        }
        .tn-additional-events__instructions {
            background-color: transparent;
            border: none;
        }
    }
    .tn-additional-events__continue-shopping-link {
        margin: $spacer-m 0;
        text-decoration: underline;
    }

    .tn-event-detail__additional-events-container {
        font-size: 85%;
        .tn-additional-events {
            margin-bottom: 0;
        }
        .tn-additional-events__controls-container {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
        .tn-additional-events__instructions {
            margin: 0;
            padding: 0;
            margin-bottom: 10px;
            background-color: transparent;
        }
        .tn-additional-events__select-view-container {
            .input-group {
                display: flex;
            }
            .input-group-btn {
                display: flex;
            }
            .tn-additional-events__date-select {
                border: 1px solid $black--40;
                width: auto;
                font-weight: bold;
                flex-grow: 1;
                max-width: 85%;
                color: $black;
            }
            .tn-additional-events__btn-submit {
                background-color: $sbc-blue;
                .glyphicon {
                    display: none;
                }
                &::after {
                    font-size: 0.9rem;
                    content: "\f061";
                    @include fa-reg;
                    display: inline-block;
                    pointer-events: none;
                    color: $white;
                }
            }
        }
    }
    .tn-ticketing-mode-change__content {
        border: none;
        padding: 0;
        background: none;
        margin: $spacer-s 0 0;
	}
    .tn-ticketing-mode-change__anchor {
        text-decoration: none;
        border-radius: 4px;
        border: 1px solid $black;
        box-shadow: inset 0px -2px 0px 0px $black;
        margin-top: 8px;
        padding: 8px 12px;
    }
	.tn-additional-events__continue-shopping-link {
        display: block;
		margin-top: 2em;
	}
}
