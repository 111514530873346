.c-global-header {
    &__parts {
        display: flex;
        align-items: center;
        padding: 8px 0;
        @include mq($from: tablet){
            padding: $spacer-s;
        }
    }
    &__lhs, &__rhs {
        flex: 1;
    }
    a {
        text-decoration: none;
    }
    &__lhs {
        display: flex;
        gap: $spacer-s;
        align-items: center;
        justify-content: flex-start;
        @include ff-heading;
        @include font-size-base-plus-1;
        a {
            @include brand-arrow;
        }
        .c-menu-trigger {
            align-items: flex-end;
            @include mq($from: tablet){
                display: none;
            }
        }
    }
    &__lhs-visit-link {
        @include mq($until: tablet){
            display: none;
        }
    }
    &__center {
        text-align: center;
    }
    &__rhs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include mq($from: tablet){
            gap: $spacer-m;
        }
        @include mq($until: tablet){
            .c-menu-trigger {
                display: none;
            }
        }
    }
    &__rhs-link {
        padding: 3px;
        display: block;
        text-align: center;
        font-weight: bold;
        @include mq($until: tablet){
            font-size: .65rem;
            text-align: center;
            margin: 0 3px;
        }
        @include mq($from: tablet){
            font-size: .8rem;
        }
    }

    &__rhs-link-icon {
        @include mq($until: tablet){
            display: block;
            font-size: .8rem;
        }
        @include mq($from: tablet){
            margin-right: 4px;
        }
    }

    &__logo-link {
        display: block;
        width: clamp(125px,18vw,300px);
        height: auto;
        margin: 0 auto;
    }
    &__account-menu {
        position: relative;
    }
    &__dropdown-toggle {
        > * {
            pointer-events: none;
        }
        &, &:hover, &:focus {
            background-color: transparent;
            color: inherit;
        }
        border: none;
        padding: 0;
        border-radius: 0;
        &[aria-expanded='true'] {
            .fa-angle-down {
                transform: rotate(180deg);
            }
        }
    }
    &__dropdown {
        text-align: left;
        position: absolute;
        z-index: 1000;
        background-color: $white;
        color: $black;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        min-width: 180px;
        top: calc(100% + 5px);
        right: -2px;
        border: 1px solid #eee;
        border-top: none;
        font-size: 80%;
        font-weight: bold;
        a {
            text-shadow: none;
            display: block;
            margin: 0.2em 0;
            padding: 0.5em 1em;
            border-bottom: 1px solid #eee;
            text-align: left;
            i, span {
                pointer-events: none;
            }
            i {
                min-width: 2.5em;
            }
        }
    }

    // TNEW specific

    .tn-timer {
        display: block;
        padding: 0.5em;
        margin: 0 0.5em;
        background-color: $state-warning-bg;
        color: $state-warning-text;
        border: 1px solid $state-warning-border;
        font-size: 80%;
        font-weight: bold;
        @include mq($until: desktop){
            order: 1;
        }
        .tn-timer-description {
            @include mq($until: tablet){
                @include text-hide;
            }
        };
    }
}


// Logged In / Loggged Out Links

.state--logged-out {

    .c-global-header__login-link {
        display: block !important;
    }
    .c-global-header__logout-link,
    .c-global-header__account-link {
        display: none !important;
    }

}

.state--logged-in {

    .c-global-header__login-link {
        display: none !important;
    }
    .c-global-header__logout-link,
    .c-global-header__account-link {
        display: block !important;
    }   

}

