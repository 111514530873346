///* ========================================================================
//   #BRAND ARROW
//   ======================================================================== */

// Adds a brand arrowhead as a ::after pseudo element

@mixin brand-arrow($colour: black) {
    &::after {
        transition: all 150ms ease;
        content: "";
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="25" viewBox="0 0 12 25" fill="none"><path d="M-0.000217438 25L2.54406 12.6364L-0.000217438 0L11.3887 12.5L-0.000217438 25Z" fill="' + $colour + '"/></svg>');
        width: .6em;
        display: inline-block;
        height: .6em;
        background-size: contain;
        background-repeat: no-repeat;
        margin-left: .6rem;
    }
}