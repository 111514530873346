.c-global-footer {
    position: relative;
    z-index: 5;
    &__wrap {
        max-width: $max-site-width;
        margin: 0 auto;
    }
    &__navigation {
        margin: $spacer-s 0;
        @include mq($from: tablet){
            margin: $spacer-l 0;
        }
        font-size: .9rem;
        .c-section-heading {
            margin-bottom: $spacer-s;
        }
    }
    &__nav-list {
        margin-bottom: $spacer-m;
    }
    &__nav-list--venues {
        .c-footer-menu {
            display: flex;
            flex-wrap: wrap;
            li {
                width: 50%;
            }
        }
        .menu-item-description {
            display: block;
            @include ff-body;
            font-weight: normal;
            font-size: .8rem;
            text-wrap: balance;
            max-width: 45ch;
            margin-top: $spacer-s;
            margin-bottom: $spacer-s;
        }
        .link-text {
            display: block;
        }
    }
    &__base-section {
        border-top: 1px solid rgba($black, .2);
        margin: $spacer-s 0;
        padding: $spacer-s 0;
        @include mq($from: tablet){
            margin: $spacer-l 0;
            padding: $spacer-l 0;
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
            align-items: center;
            height: 100%;
        }
    }
    ul {
        margin: 0; padding: 0; list-style: none;
    }
    a {
        display: block;
        text-decoration: none;
        padding: 3px;
    }
    &__lhs {
        display: flex;
        flex-direction: column;
        gap: $spacer-s;
        align-items: center;

        @include mq($from: tablet) {
            align-items: flex-start;
        }

        justify-content: flex-start;
        flex-direction: column;

    }
    &__center {
        text-align: center;
    }
    &__rhs {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
    &__logo-link {
        display: block;
        width: clamp(220px,22vw,340px);
        height: auto;
        margin: 0 auto $spacer-m;
    }
    &__social-section {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include mq($until: tablet){
            justify-content: center;
            width: 100%;
            margin: $spacer-m 0 0 0;
        }
        &-title {
            @include ff-body;
            font-size: .8rem;
            font-weight: bold;
            margin-bottom: $spacer-xs;
            width: 100%;
            @include mq($until: tablet){
                text-align: center;
            }
        }
        ul {
            display: flex;
            flex-wrap: wrap;
        }
        li {
            margin-right: $spacer-xs;
            margin-bottom: 0;
        }
        a {
            padding: $spacer-xs;
            i {
                font-size: 1.3rem;
                color: black(85);
            }
        }
    }
    &__sponsor-logos {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: $spacer-m 0;
        gap: $spacer-m;
        @include mq($until: tablet){
            width: 100%;
        }
    }
    &__sponsor-logo-img {
        display: block;
        max-width: 200px;
        margin-bottom: $spacer-m;
    }
    &__sponsor-logo--bloomberg {
        img {
            max-width: 170px;
        }
    }
    &__sponsor-logo-label {
        display: block;
        font-size: .7rem;
    }
    &__copyright {
        font-size: .7rem;
        line-height: 1.5;
        max-width: 40ch;
        margin: 0 auto;
    }
    .c-btn--chat {
        flex: none;
        font-size: .7rem;
    
        @include mq($until: tablet) {
            margin-bottom: $spacer-m;
        }
    
    }
    &__star-logo {
        display: block;
        text-align: center;
        margin-top: $spacer-m;
        img {
            max-width: 150px;
        }
    }
}


/* Hide the default Zendesk chat button */
[data-testid="launcher"], #launcher {
    display: none !important;
}


