///* ========================================================================
//   Calculate font size for screens between two breakpoints - fluid type
//   https://css-tricks.com/snippets/css/fluid-typography/
//
//   Uses inuit-font-size as a fallback to output rules for screens outside the given breakpoints
//   ======================================================================== */

@mixin fluid-type($min-font-size, $max-font-size: $min-font-size, $line-height: auto, $min-vw: $tablet, $max-vw: $wide ) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 ==$u2 and $u1 ==$u3 and $u1 ==$u4 {
        & {
            @include inuit-font-size($min-font-size, $line-height);

            @if $min-font-size !=$max-font-size {
                @media screen and (min-width: $max-vw) {
                    @include inuit-font-size($max-font-size, $line-height);
                }

                @media screen and (min-width: $min-vw) and (max-width: $max-vw) {
                    // Use rems for fluid type calcuations
                    $min-font-size: to-rem($min-font-size);
                    $max-font-size: to-rem($max-font-size);
                    $min-vw: to-rem($min-vw);
                    $max-vw: to-rem($max-vw);

                    font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
                }
            }
        }
    }
}

// Type scale adjustments can be made at:
// https://www.fluid-type-scale.com/calculate?minFontSize=16&minWidth=400&minRatio=1.2&maxFontSize=19&maxWidth=1280&maxRatio=1.2&steps=base-minus-2%2Cbase-minus-1%2Cbase%2Cbase-plus-1%2Cbase-plus-2%2Cbase-plus-3%2Cbase-plus-4%2Cbase-plus-5%2Cbase-plus-6%2Cbase-plus-7%2Cbase-plus-8&baseStep=base&prefix=font-size&decimals=0&previewFont=Inter&previewText=Almost+before+we+knew+it%2C+we+had+left+the+ground&previewWidth=1280

@mixin font-size-base-minus-2 {
    @include fluid-type(11px, 13px, 1.5);
}
@mixin font-size-base-minus-1 {
    @include fluid-type(13px, 16px, 1.5);
}
@mixin font-size-base {
    @include fluid-type(16px, 19px, 1.5);
}
@mixin font-size-base-plus-1 {
    @include fluid-type(19px, 23px, 1.3);
}
@mixin font-size-base-plus-2 {
    @include fluid-type(23px, 27px, 1.2);
}
@mixin font-size-base-plus-3 {
    @include fluid-type(28px, 33px, 1.1);
}
@mixin font-size-base-plus-4 {
    @include fluid-type(33px, 39px, 1.1);
}
@mixin font-size-base-plus-5 {
    @include fluid-type(40px, 47px, 1);
}
@mixin font-size-base-plus-6 {
    @include fluid-type(48px, 57px, 1);
}
@mixin font-size-base-plus-7 {
    @include fluid-type(57px, 68px, .9);
}
@mixin font-size-base-plus-8 {
    @include fluid-type(69px, 82px, .9);
}

