// Override One Trust default colours

body {
    #onetrust-consent-sdk #onetrust-accept-btn-handler,
    #onetrust-banner-sdk #onetrust-reject-all-handler {
        background-color: $sbc-blue !important;
        border-color: $sbc-blue !important;
        color: $white !important;
    }
    #onetrust-consent-sdk #onetrust-pc-btn-handler, #onetrust-consent-sdk #onetrust-pc-btn-handler.cookie-setting-link {
        background-color: $white !important;
        border-color: $black !important;
        color: $black !important;
    }
    #onetrust-policy-text {
        color: $black !important;
    }
    #onetrust-pc-sdk {
        #accept-recommended-btn-handler,
        .ot-pc-refuse-all-handler,
        .save-preference-btn-handler {
            background-color: $sbc-blue !important;
            border-color: $sbc-blue !important;
            color: $white !important;
        }
    }
}


// Cookie Settings button in footer

button#ot-sdk-btn.ot-sdk-show-settings  {
    font: inherit;
    flex: none;
    background:none !important;
    border: none !important;
    font-size: 0.65rem;
    margin-top: $spacer-s !important;
    text-decoration: underline;
    text-underline-offset: 3px;
    opacity: 0.7;
    color: $black !important;

}

// Hide floating button

#ot-sdk-btn-floating.ot-floating-button {
    display: none;
}