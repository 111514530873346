// TNEW Overrides
.tn-upcoming-event__list-item {
  flex: 0 0 100%;
  margin: 0 0 10px;
}
.tn-upcoming-event__status-message {
  display: none;
}

.twc-digital-wallet {
  margin: 15px 0 0;
  .c-btn--digital-wallets {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    padding: 0 !important;
    margin: 0 15px 10px 0;
  }
  .c-btn--apple-wallet-add {
    img {
      width: 120px;
    }
  }
  .c-btn--google-wallet-add {
    img {
      width: 136px;
    }
  }

  p {
    max-width: 600px;
  }

  .c-digital-wallets--tickets {
    h3 {
      margin: 0 0 25px;
    }
    .c-digital-wallets--ticket {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 40px;
      h3 {
        margin-top: 0;
      }
      p {
        margin: 0;
      }
      .c-btn--digital-wallets {
        margin-top: 15px;
      }
    }
  }
  .loading-apple-wallet {
    position: relative;
    width: 120px;
    height: 37.07px;
    background-color: #000!important;
    border-radius: 7px;
    margin: 0 15px 0 0;
  }
  .loading-google-wallet {
    position: relative;
    width: 136px;
    height: 37.59px;
    background-color: #000!important;
    border-radius: 25px;
    margin: 0 15px 0 0;
  }
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }
  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #000;
    animation: spinner .6s linear infinite;
  }
}
