.tnew-message {
    font-size: 90%;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin: 15px auto;
    color: $black;
    background-color: $info-light;
    border: 1px solid rgba($info, .15);
    border-top: 3px solid $info;
    box-shadow: $global-box-shadow-small;
    padding: $spacer-s;
    @include mq($from: desktop){
        padding: $spacer-m;
    }
    * {
        margin-top: 0;
        margin-bottom: $spacer-xs;
    }
    &__img {
        width: 20%;
        max-width: 200px;
        height: auto;
        margin-right: 5%;
        margin-bottom: 0;
    }
    &__content {
        flex-grow: 1;
        margin-bottom: 0 !important;
    }
}

.tn-heading-info:has(.tnew-message) {
    border: none;
    background-color: transparent;
    padding: 0;
    br {
        display: none;
    }
}