.tn-syos {
	.tn-syos-screen-button {
		background-color: $sbc-blue;
		border: 0;
		border-radius: $global-radius;
		font-weight: bold;
		transition: $global-transition;
		&:hover {
			transform: scale(1.015);
		}
		&.tn-syos-screen-container__button--seat-map-hover-active {
			transform: scale(1.015);
		}
	}
	.tn-syos-screen-container__button-list-heading {
		font-size: 1rem;
		@include ff-body;
		font-weight: bold;
		color: $black;
	}
	.tn-syos-screen-button__section-heading {
		color: inherit;
		font-weight: bold;
	}
	.btn-primary, .tn-perf-listing-action {
		@include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
	}
	.tn-syos-mobile-cart-controls__summary {
		font-size: 80%;
	}
	.tn-syos__btn-add-to-cart {
		text-transform: none;
		font-weight: 700;
		&:not(:disabled) {
			color: $btn-primary-color;
			background-color: $btn-primary-bg;
			  &:hover,
			  &:focus,
			  &.focus,
			  &:active,
			  &.active {
			    background-color: darken($btn-primary-bg, 5%);
			}
		}
	}
	.tn-syos-btn-view-screens {
		border-radius: 4px;
		border: 1px solid $black;
		box-shadow: inset 0px -2px 0px 0px $black;
		font-weight: bold;
	}
	.tn-syos-legend__desktop-heading {
		font-size: 1rem;
		@include ff-body;
		font-weight: bold;
		color: $black;
	}
	.tn-syos-seat-info__price-type-heading {
		font-size: 1rem;
		@include ff-body;
		font-weight: bold;
		color: $black;
	}
	.tn-syos-seat-info__price-type--button {
		background-color: $white;
		border-radius: 4px;
        border: 1px solid $black;
        box-shadow: inset 0px -2px 0px 0px $black;
	}
	.tn-syos__layout-container {
		margin-bottom: $spacer-m;
	}
}